import CheckIcon from '@mui/icons-material/Check';
import { Alert, Autocomplete, Button, TextField, TextareaAutosize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import addIcon from '../../../assets/Business/addIcon.png';
import { SendEmailForUsers, getComapnyData, getCompanyGroups, getUsers } from '../../../service/businessAPI/business';
export default function EmailNotification() {
    const [optionsDataUsers, setOptionsDataUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [companyLogo, setCompanyLogo] = useState("");

    const [assignTo, setAssignTo] = useState(null);
    const handleAssignToChange = (selectedOption) => {
        setAssignTo(selectedOption);
    };
    const [notificationSubject, setNotificationSubject] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    const [optionsDataGroups, setOptionsDataGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    // const [usersLoading, setUsersLoading] = useState(true);
    const [groupLoading, setGroupLoading] = useState(true);


    const toShowAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            // Clear all inputs after hiding the alert
            setSelectedUsers([]);
            setAssignTo(null);
            setNotificationSubject('');
            setNotificationBody('');
        }, 5000);
    };

    const handleInputNotificationSubjectChange = (event) => {
        setNotificationSubject(event.target.value);
    };

    const handleInputNotificationBodyChange = (event) => {
        setNotificationBody(event.target.value);
    };

    const eyouthPublicClient = JSON.parse(
        localStorage.getItem("userPublic_stu") || {}
    );
    let CompanyName = 'Taibah'
    let token = eyouthPublicClient.access_token;

    useEffect(() => {
        getUsers('1', CompanyName, "20000", "", "", "")
            .then((result) => {
                // setUsersLoading(false)
                const formattedOptionsUsers = result.results.map(item => ({
                    value: item.email,
                    label: item.username,
                }));
                setOptionsDataUsers(formattedOptionsUsers);
                // setUsersLoading(true)

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
        getComapnyData(CompanyName).then((result) => {
            setCompanyLogo(result.data[0].logo);
        })

            .catch((error) => {
                console.error('API Error:', error);
            });

    }, [CompanyName]);
    const customFilter = (option, searchText) => {
        if (
            option.label.toLowerCase().includes(searchText.toLowerCase()) ||
            option.value.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        getCompanyGroups(CompanyName)
            .then((result) => {
                setGroupLoading(true)
                const formattedOptionsGroups = result.data.map(item => ({
                    value: item.group_name,
                    label: item.group_name,
                }));

                setOptionsDataGroups(formattedOptionsGroups);
                setGroupLoading(false)

            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    }, [CompanyName]);

    useEffect(() => {
        setIsFormValid(
            (assignTo && (
                (assignTo.value === 'group' && selectedGroup.length > 0) ||
                (assignTo.value === 'emails' && selectedUsers.length > 0)
            )) &&
            notificationSubject.trim() !== '' &&
            notificationBody.trim() !== ''
        );
    }, [assignTo, selectedGroup, selectedUsers, notificationSubject, notificationBody]);

    let companyLogoFinal = `https://business-django.eyouthlearning.com${companyLogo}`;

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let test;

        if (assignTo.value === 'group') {
            test = await SendEmailForUsers(notificationSubject, selectedGroup, notificationBody, companyLogoFinal, token, CompanyName, assignTo.value);
            if (test.data === "Bulk Email Sent") {
                toShowAlert();
            }
        } else {
            test = await SendEmailForUsers(notificationSubject, selectedUsers, notificationBody, companyLogoFinal, token, CompanyName, assignTo.value);
            if (test.data === "Bulk Email Sent") {
                toShowAlert();
            }
        }
    };

    return (
        <div className='container'>
            <div className='d-flex align-items-center mt-4 '>
                <img width='25px' src={addIcon} alt="Add Icon" />
                <h4 className='mx-2 my-0 p-0' style={{ color: "#283B91" }}> Send Notification  </h4>
            </div>
            <form onSubmit={handleFormSubmit}>
                <h5 className='mt-5' style={{ color: "#283B91" }}> Select Send Emails or Group </h5>
                <Select
                    className='w-25 my-3'
                    options={[
                        { value: 'group', label: 'Groups' },
                        { value: 'emails', label: 'Users' }
                    ]}
                    onChange={handleAssignToChange}
                    isSearchable={false}
                />
                {assignTo && (
                    <div>
                        {assignTo.value === 'group' ? (
                            <>
                                <div className='my-5 d-flex'>
                                    <div className='col-5'>
                                        <h5 style={{ color: "#283B91" }}> Choose Groups</h5>
                                        <Select styles={{ width: "22vw" }}
                                            isLoading={groupLoading} // Use the isLoading prop to indicate loading state
                                            loadingMessage={() => <> loading data.... </>} // Customize the loading message

                                            options={optionsDataGroups} onChange={(selectedOption) => {
                                                setSelectedGroup(selectedOption.value);
                                            }} />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='my-5 d-flex'>
                                    <div className='col-5'>
                                        <h5 style={{ color: "#283B91" }}> Choose users</h5>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={optionsDataUsers}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Users" />}

                                            onChange={(event, selectedOptions) => {
                                                if (Array.isArray(selectedOptions)) {
                                                    const selectedUserLabels = selectedOptions.map(option => option.value);
                                                    setSelectedUsers(selectedUserLabels.join(', '));
                                                } else if (selectedOptions) {
                                                    setSelectedUsers(selectedOptions.value);
                                                }
                                            }}
                                            filterOption={customFilter}
                                        />
                                    </div>
                                </div>
                            </>)}
                    </div>
                )}
                <div className='my-4'>
                    <h5 style={{ color: "#283B91" }}> Email  Subject</h5>
                    <input
                        className="form-control w-50 form--contact-us-input single-box"
                        placeholder="Notification Subject"
                        type="text"
                        name="name"
                        id="exampleFormControlInput1"
                        value={notificationSubject}
                        onChange={handleInputNotificationSubjectChange}
                    />
                </div>

                <div className='my-4'>
                    <h5 style={{ color: "#283B91" }}> Message Body</h5>
                    <TextareaAutosize
                        placeholder=" Message Body"
                        className="form-control w-50 form--contact-us-input single-box"
                        minRows={3}
                        value={notificationBody}
                        onChange={handleInputNotificationBodyChange}
                    />
                </div>
                {showAlert && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Email Send successfully
                </Alert>}
                <Button type='submit' variant="contained" disabled={!isFormValid}>
                    Send Notification
                </Button>

            </form>
        </div>
    )
}
