
export const HOST = window.location.origin;
export const WEBSITE_URL = "https://taibahuniversity.eyouthlearning.com";
export const APIExperience = "https://taibah.eyouthlearning.com";
export const STUDIO_BASE_URL = "https://studio.taibah.eyouthlearning.com";
export const ECOMMERCE_URL = "https://ecommerce.taibah.eyouthlearning.com";
export const DISCOVERY_URL = "https://discovery.taibah.eyouthlearning.com";
export const APIForms = "https://forms.eyouthlearning.com";
export const PROFILE_IMAGES_PREFIX = "https://files.taibah.eyouthlearning.com/openedx/openedx";
export const APIDev = "https://learning.business.eyouthlearning.com";


export const CATEGORIES_LIST = [
    "TECHNICAL",
    "MARKETING",
    "BANKING",
    "LANGUAGES",
    "FREELANCING",
    "SALES",
    "SOFT_SKILLS",
    "PROGRAMMING_AND_TECHNOLOGY",
    "BUSINESS_AND_MANAGEMENT",
    "EDUCATION",
    "CAREER_DEVELOPMENT",
    "DESIGNING",
    "ENTREPRENEURSHIP",
    "HR",
    "OPERATIONS",
    "TRAINING_AND_DEVELOPMENT",
    "TOURISM",
    "TECHNOLOGY_SCIENCE",
    "OIL_AND_GAS",
    "FOOTBALL_COMMENTARIES",
    "MEDIA_PHOTOGRAPHY_AND_FILM",
    "PR",
    "SCIENCE",
    "HUMANITARIAN_WORK",
    "TEACHING_AND_ACADEMICS",
    "IT_AND_SOFTWARE",
    "PHARMACY",
    "E-COMMERCE",
    "FINANCE_AND_ACCOUNTING",
    "PM",
    "AVIATION"
];
export const NBD_Courses = ["course-v1:Banking+FISZZYH2023+FIUKAEX2023", "course-v1:Banking+CPV8LII2023+CPXMGS42023", "course-v1:Banking+SSKLMC62023+SSWF5NI2023"];

const CONSTANTS = {
    HOST,
    STUDIO_BASE_URL,
    APIExperience,
    APIForms,
    PROFILE_IMAGES_PREFIX,
    CATEGORIES_LIST, NBD_Courses
};

export default CONSTANTS;