import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CallIcon from "@mui/icons-material/Call";
import DiamondIcon from "@mui/icons-material/Diamond";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import SchoolIcon from "@mui/icons-material/School";
// import SellIcon from "@mui/icons-material/Sell";
import SensorsIcon from "@mui/icons-material/Sensors";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Buffer } from "buffer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Sticky, StickyContainer } from "react-sticky";
import white from "../../../assets/Vector_(19).webp";
import foundationtorsobusiness from "../../../assets/foundation_torso-business.webp";
import IconTeacher from "../../../assets/la_chalkboard-teacher_(1).webp";
import logo from "../../../assets/TebaImages/logo-taibah 1.png";
import { CATEGORIES_LIST } from "../../../config/constants";
import Services from "../../../config/services/authServices";
import { axiosInstace } from "../../../service/axiosConfig";
import Searchbar from "../../search/search";
import styles from "../navbar.module.css";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LangButton from "../../LangButton/LangButton";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MobileNavbar() {
  const [URLSearchParams] = useSearchParams();
  const [profilePic, setProfilePic] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorElMobile, setAnchorElMobile] = useState(null);
  const openMobile = Boolean(anchorElMobile);
  const [showCatRecorded, setShowCatRecorded] = useState(false);
  const [showCatLive, setShowCatLive] = useState(false);
  const [session, setSession] = useState(
    JSON.parse(localStorage.getItem("user_stu") || "{}")
  );
  const navigatesignin = () => navigate(`/signin?redirect=${pathname}`);
  const navigatesignup = () => navigate(`/signup?redirect=${pathname}`);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMobile = (event) => {
    setAnchorElMobile(event.currentTarget);
  };
  const handleCloseMobile = () => {
    setAnchorElMobile(null);
  };
  const handleLogOut = async () => {
    handleClose();
    localStorage.removeItem("user_stu");
    await Services.logout();
    navigate("/");
  };
  const [token, setToken] = useState();
  useEffect(() => {
    if (token?.length > 10) {
      localStorage.setItem("user_stu", token);
      setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
    }
  }, [token]);

  useEffect(() => {
    setToken(
      Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString(
        "utf-8"
      )
    );
    setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
  }, [location, URLSearchParams]);

  useEffect(() => {
    if (Object.keys(session).length > 0) {
      axiosInstace
        .get(`/api/user/v1/accounts/${session.username}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${session.access_token}`,
          },
        })
        .then((res) => {
          if (res.data.profile_image.has_image) setProfilePic(res.data.profile_image.image_url_full);
        })
        .catch((err) => console.log(err.message));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.mobile_div_navbar}>
      <div>
        <Button
          aria-label="button to be clicked"
          aria-labelledby="button to be clicked"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon className={`${styles.icon_color} ${styles.icon_nav_burger}`} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="d-flex flex-column"
          style={{ fontFamily: "Tajawal", padding: "10px" }}
        >
          {!showCatRecorded && !showCatLive ? (
            <MenuItem style={{ fontFamily: "Tajawal" }} className="w-100">
              <Searchbar classes="w-100 searchbar-mobile" />
            </MenuItem>
          ) : (
            <>
              {showCatRecorded ? (
                <div
                  onClick={() => {
                    setShowCatRecorded((prev) => !prev);
                  }}
                  className={`d-flex align-items-center ${styles.icon_color} mb-2 `}
                >
                  <ArrowForwardIosIcon className={`${styles.icon_color} ${styles.icon_arrow_width}  ms-1`} />
                  <h6 className="p-0 m-0"> {t("Back")}</h6>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setShowCatLive((prev) => !prev);
                  }}
                  className={`d-flex align-items-center ${styles.icon_color} mb-2 `}
                >
                  <ArrowForwardIosIcon className={`${styles.icon_color} ${styles.icon_arrow_width}  ms-1`} />
                  <h6 className="p-0 m-0"> {t("Back")}</h6>
                </div>
              )}
            </>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem style={{ fontFamily: "Tajawal" }}>
              <LangButton />
            </MenuItem>)}

          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-100"
            >
              <NavLink
                end
                to="/"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <HomeIcon className={`${styles.icon_color} ms-2`} />
                  {t("home")}
                </div>
              </NavLink>
            </MenuItem>
          )}

          {!showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={() => setShowCatRecorded((prev) => !prev)}
            >
              <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text} align-items-center`}>
                <VideoLibraryIcon className={`${styles.icon_color} ms-2`} />
                <span className={!showCatLive ? "font-size-h1" : ""}>
                  {t("Interactive_Recorded_Courses")}
                </span>
                <ArrowBackIosIcon className={styles.icon_arrow_width} />
              </div>
            </MenuItem>
          )}
          {showCatRecorded ? (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-100"
            >
              <li className="dropdown-list-menuItem d-flex">
                <StickyContainer>
                  <Sticky topOffset={100}>
                    {({ style }) => (
                      <div
                        style={{ ...style, top: "150px" }}
                        className=" fixed-arrow"
                      ></div>
                    )}
                  </Sticky>
                </StickyContainer>
                <div className="d-flex justify-content-start row px-4 dropdown-list-menuItem1">
                  {CATEGORIES_LIST.map((category, index) => (
                    <a
                      key={index}
                      href={
                        "/all-courses?pacing=true&org=" + category.toLowerCase()
                      }
                      className="col-md-3 my-3"
                    >
                      - {t(category)}
                    </a>
                  ))}
                </div>
              </li>
            </MenuItem>
          ) : null}
          {!showCatRecorded && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={() => setShowCatLive((prev) => !prev)}
            >
              <div className={`"d-flex justify-content-start w-100 ${styles.icon_color_text} align-items-center"`}>
                <SensorsIcon className="icon-color ms-2" />
                <span className={!showCatRecorded ? "font-size-h1" : ""}>

                  {t("LIVE_Courses")}
                </span>
                <ArrowBackIosIcon className={`${styles.icon_arrow_width}`} />
              </div>
            </MenuItem>
          )}
          {showCatLive ? (
            <MenuItem style={{ fontFamily: "Tajawal" }} className="w-100">
              <li className="dropdown-list-menuItem d-flex">
                <StickyContainer>
                  <Sticky topOffset={100}>
                    {({ style }) => (
                      <div
                        style={{ ...style, top: "150px" }}
                        className=" fixed-arrow"
                      ></div>
                    )}
                  </Sticky>
                </StickyContainer>

                <div className="d-flex justify-content-start row px-4">
                  {CATEGORIES_LIST.map((category, index) => (
                    <a
                      key={index}
                      href={
                        "/all-courses?pacing=false&org=" +
                        category.toLowerCase()
                      }
                      className="col-md-3 my-3"
                    >
                      - {t(category)}
                    </a>
                  ))}
                </div>
              </li>
            </MenuItem>
          ) : null}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-100 "
            >
              <NavLink
                to="/all-courses"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <NewspaperIcon className={`${styles.icon_color} ms-2`} />
                  {t("All_courses")}
                </div>
              </NavLink>
            </MenuItem>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem style={{ fontFamily: "Tajawal" }} onClick={handleClose}>
              <NavLink
                reloadDocument
                to="/quick-learnings"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <SchoolIcon className={`${styles.icon_color} ms-2`} />
                  {t("Quick_learn_courses")}
                </div>
              </NavLink>
            </MenuItem>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-100 "
            >
              <NavLink
                to="/programs"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <AutoStoriesIcon className={`${styles.icon_color} ms-2`} />
                  {t("Our_programs")}
                </div>
              </NavLink>
            </MenuItem>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-100 "
            >
              <NavLink
                to="/our-projects"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <DiamondIcon className={`${styles.icon_color} ms-2`} />
                  {t("Our_projects")}
                </div>
              </NavLink>
            </MenuItem>
          )}
          {/* {(!showCatRecorded && !showCatLive) && <MenuItem style={{ fontFamily: "Tajawal" }} onClick={handleClose} className="w-100">
                    <NavLink reloadDocument to="/wfp?page=1" className={({ isActive }) => isActive ? `${styles.active_nav}  w-100` : 'w-100'} >
                      <div className="d-flex justify-content-start w-100 ${styles.icon_color_text}">
                        <SupportAgentIcon className={`${styles.icon_color} ms-2`} />
                        WF -Program
                      </div>
                    </NavLink>
                  </MenuItem>} */}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-100 "
            >
              <NavLink
                to="/studio/#contactUsStudio"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <LazyLoadImage
                    alt="icon"
                    src={white}
                    className={`ms-2 ${styles.active_icon_2}`}
                    width={"11%"}
                  />
                  <LazyLoadImage
                    alt="icon"
                    src={IconTeacher}
                    className={`ms-2 ${styles.active_icon_1}`}
                    width={"11%"}
                  />
                  {t("Join_EYouth_Instructors")}
                </div>
              </NavLink>
            </MenuItem>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-75 "
            >
              <a
                href="https://business.eyouthlearning.com/"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <LazyLoadImage
                    alt="icon"
                    src={foundationtorsobusiness}
                    className="ms-2"
                    width={"13%"}
                  />
                  {t("Train_your_business_team")}
                </div>
              </a>
            </MenuItem>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-75 "
            >
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <CallIcon className={`${styles.icon_color} ms-2`} />
                  {t("footer_contact_us")}
                </div>
              </NavLink>
            </MenuItem>
          )}
          {!showCatRecorded && !showCatLive && (
            <MenuItem
              style={{ fontFamily: "Tajawal" }}
              onClick={handleClose}
              className="w-75 "
            >
              {/* <NavLink
                to="/about-eyouth"
                className={({ isActive }) =>
                  isActive ? `${styles.active_nav}  w-100` : "w-100"
                }
              >
                <div className={`d-flex justify-content-start w-100 ${styles.icon_color_text}`}>
                  <CallIcon className={`${styles.icon_color} ms-2`} />
                  {t("About")}
                </div>
              </NavLink> */}
            </MenuItem>
          )}
        </Menu>
      </div>

      <Link to="/">
        <LazyLoadImage src={logo} width="55px" alt="logo" />
      </Link>
      {Object.keys(session).length > 0 && pathname !== "/studio" ? (
        <div className="d-flex align-items-center">
          <Button
            aria-label="button to be clicked"
            aria-labelledby="button to be clicked"
            id="fade-button"
            aria-controls={openMobile ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMobile ? "true" : undefined}
            onClick={handleClickMobile}
            className={`${styles.profile_btn} bg-light border-button rounded p-1`}
          >
            {!profilePic ? <AccountCircleIcon className={styles.icon_color} />
              : <LazyLoadImage src={profilePic} alt="icon" style={{ borderRadius: "11px", width: "22px", height: "22px", marginInline: "1px" }} />}
            <p className="m-0 p-0">
              {session?.username.length > 7
                ? session?.username.slice(0, 7)
                : session?.username}
            </p>
            <KeyboardArrowDownIcon className={styles.icon_color} />
          </Button>
          <Menu
            style={{ zIndex: "999999" }}
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorElMobile}
            open={openMobile}
            onClose={handleCloseMobile}
            TransitionComponent={Fade}
          >
            <MenuItem
              style={{ fontFamily: "Tajawal", color: "#0BB7C1" }}
              onClick={handleCloseMobile}
              className="fs-5"
            >
              <Link className={styles.mobileLink} to="/Mycourses">{t("My Courses")}</Link>
            </MenuItem>

            <MenuItem
              style={{ color: "#0BB7C1" }}
              onClick={handleCloseMobile}

            >
              <h5
                onClick={() =>
                  (window.location.href = `https://apps.Taibah.eyouthlearning.com/profile/u/${session?.username}`)
                }
              >
                {t("My_profile")}
              </h5>
            </MenuItem>
            <MenuItem
              style={{ fontFamily: "Tajawal", color: "#0BB7C1" }}
              onClick={handleCloseMobile}
              className="fs-5"
            >
              <a className={styles.mobileLink} href="https://apps.eyouthlearning.com/account">
                {t("Account_Settings")}
              </a>
            </MenuItem>

            <MenuItem
              style={{ fontFamily: "Tajawal", color: "#0BB7C1" }}
              onClick={handleCloseMobile}
              className="fs-5"
            >
              <a className={styles.mobileLink} href="https://apps.eyouthlearning.com/orders/orders">
                {t("My_orders")}
              </a>
            </MenuItem>
            <MenuItem style={{ fontFamily: "Tajawal" }} onClick={handleLogOut}>
              <button
                aria-label="button to be clicked"
                aria-labelledby="button to be clicked"
                className="btn btn-light border m-auto"
              >
                {t("Sign Out")}
              </button>
            </MenuItem>
          </Menu>
          <a
            href="https://apps.eyouthlearning.com/payment"
            className="mx-1"
          >
            <strong>
              <ShoppingCartIcon className={styles.shoppingCart_mobile} />
            </strong>
          </a>
        </div>
      ) : (
        <div className="d-flex gap-2 px-2">
          <button
            aria-label="button to be clicked"
            aria-labelledby="button to be clicked"
            className={`btn ${styles.btn_signin}`}
            onClick={navigatesignin}
          >
            {t("Sign In")}
          </button>
          <button
            aria-label="button to be clicked"
            aria-labelledby="button to be clicked"
            className={`btn ${styles.btn_signin}`}
            onClick={navigatesignup}
            style={{color: "red"}}
          >
            {t("Join_Us")}
          </button>
        </div>
      )}
    </div>
  );
}

export default MobileNavbar;
