import React from 'react';
import introImage from '../../assets/About/Header.jpg';
import MobileImage from '../../assets/About/newMobileVersionsAbout.jpg';
import AboutEyouthImage from '../../assets/About/AboutEyouthEN.jpg';
import PlatformFromTheFutur from '../../assets/About/PlatformFromTheFutur.png';
import line from '../../assets/line.webp';
import LastSection from '../../assets/About/lastSection.png';
import styles from './About.module.css';

function About() {
  return (
    <div>
      <img width="100%" src={introImage} alt="introImage" srcset="" />
      <div className={styles.backgroundMobileDiv}>
        <img width="100%" src={MobileImage} alt="MobileImage" srcset="" />
      </div>
      <img width="100%" src={AboutEyouthImage} alt="MobileImage" srcset="" />
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <h3 className={`mt-5 ${styles.platformH3}`}  >
          A platform built for the future
        </h3>
        <img src={line} className='mb-5' alt="line" />
        <img width="90%" className='mb-5' src={PlatformFromTheFutur} alt="MobileImage" srcset="" />
      </div>
      <div dir='ltr' className={styles.comptative}>
        <div >
          <h1 className={`text-white ${styles.textinsidecomptative}`}>
            Competitive advantage with <br></br>local language coverag
          </h1>
          <p className={`text-white ${styles.textinsidecomptativep}`}>We provide programs in Arabic, English ,  French with local instructors to provide a  localized content for youth in all MENA  countries.</p>
        </div>
      </div>
      <div className='d-flex justify-content-center my-5'>

        <img width="70%" src={LastSection} alt="MobileImage" srcset="" />
      </div>
    </div>
  );
}

export default About;