import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import logo from "../../assets/TebaImages/logo-taibah 1.png";
import styles from "./navbar.module.css";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Fade from "@mui/material/Fade";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import LanguageIcon from "@mui/icons-material/Language";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { STUDIO_BASE_URL } from "../../config/constants";
import Services from "../../config/services/authServices";
import { axiosInstace } from "../../service/axiosConfig";
import PopUpJoinUs from "../PopUpJoinUs/PopUpJoinUs";
import Searchbar from "../search/search";
import CategoriesList from "./CategoriesList";
import MobileNavbar from "./MobileNavbar/MobileNavbar";
import LangButton from "../LangButton/LangButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Navbar({ isLive }) {
  // const [profilePic, setProfilePic] = useState("");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [URLSearchParams] = useSearchParams();

  const [session, setSession] = useState(
    JSON.parse(localStorage.getItem("user_stu") || "{}"));
  const navigateHome = () => navigate("/");
  const navigatesignin = () => navigate(`/signin?redirect=${pathname}`);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = async () => {
    handleClose();
    localStorage.removeItem("user_stu");
    await Services.logout();
    navigate("/");
  };
  const [token, setToken] = useState();
  useEffect(() => {
    if (token?.length > 10) {
      localStorage.setItem("user_stu", token);
      setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
    }
  }, [token]);

  useEffect(() => {
    setToken(
      Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString(
        "utf-8"
      )
    );
    setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
  }, [location, URLSearchParams]);

  useEffect(() => {
    if (session.username) {
      axiosInstace
        .get(`/api/user/v1/accounts/${session.username}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${session.access_token}`,
          },
        })
        .then((res) => {
          // setProfilePic(res.data.profile_image.image_url_full);
        })
        .catch((err) => console.log({ err }));
    }
    // eslint-disable-next-line
  }, []);
  // console.log(profilePic, "profilePic");

  let MobileViewusername = session?.username || [];
  let usernameInMobileView = MobileViewusername.slice(0, 10);
  return (
    <>
      <MobileNavbar />

      <div className={`bg-light ${styles.nav_bar}`}>
        <div
          className={`d-flex ${pathname !== "/checkout-preview" &&
            "align-items-center justify-content-around"
            }  w-100 border-bottom`}
        >
          <div className="col-md-5 col-sm-6 d-flex align-items-center px-1 ">
            {pathname !== "/checkout-preview" && (
              <div>
                <ul className={`d-flex align-items-center w-100 p-0 m-0`}>
                  <img
                    alt="eyouth-logo"
                    loading="eager"
                    src={logo}
                    className={styles.logo_image}
                    onClick={navigateHome}
                  />
                  <div className="">
                    <div className={styles.navigation}>
                      <a
                        style={{ fontSize: "15px" }}
                        className="nav-link d-flex hover-underline-animation mx-1"
                        href="/"
                      >
                        {t("home")}
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className={styles.navigation}>
                      <a href="/all-courses">
                        {t("Training_programs")}
                        <KeyboardArrowDownIcon style={{ fontSize: "11px" }} />
                      </a>
                      <div
                        className={styles.navigation_content_last}
                        style={{ minWidth: "10vw !important" }}
                      >
                        <div>
                          <a
                            style={{ fontSize: "15px", width: "17vw" }}
                            className="nav-link d-flex hover-underline-animation mx-1"
                            href="/all-courses"
                          >
                            {t("All_courses")}
                          </a>
                        </div>
                        <div
                          className={
                            i18n.language === "ar"
                              ? styles.navigation2
                              : styles.navigation2EN
                          }
                        >
                          <a
                            style={{ fontSize: "15px", width: "17vw" }}
                            className="d-flex mx-1"
                            href="/all-courses?pacing=false"
                          >
                            {t("LIVE_Courses")}
                            <KeyboardArrowDownIcon
                              style={{ fontSize: "11px" }}
                            />
                          </a>
                          <div
                            className={styles.navigation_content_last2}
                            style={{ minWidth: "10vw !important" }}
                          >
                            {<CategoriesList isLive />}
                          </div>
                        </div>

                        <div
                          className={
                            i18n.language === "ar"
                              ? styles.navigation2
                              : styles.navigation2EN
                          }
                        >
                          <a
                            style={{ fontSize: "15px", width: "17vw" }}
                            className="nav-link d-flex hover-underline-animation mx-1"
                            href="/all-courses?pacing=true"
                          >
                            {t("Interactive_Recorded_Courses")}
                            <KeyboardArrowDownIcon
                              style={{ fontSize: "11px" }}
                              className="icon-none-sm mx-1 keyboardArrow"
                            />
                          </a>
                          <div
                            className={styles.navigation_content_last2}
                            style={{ minWidth: "10vw !important" }}
                          >
                            {<CategoriesList />}
                          </div>
                        </div>

                        {/* <div>
                          <a
                            style={{ fontSize: "15px", width: "17vw" }}
                            className="nav-link d-flex hover-underline-animation mx-1"
                            href="/quick-learnings"
                          >
                            {t("Quick_learn_courses")}
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>


                  <div className="">
                    <div className={styles.navigation}>
                      <a
                        style={{ fontSize: "15px" }}
                        className="nav-link d-flex hover-underline-animation mx-1"
                        href="/programs"
                      >
                        {t("Our_programs")}
                      </a>
                    </div>
                  </div>
                  <div className="">
                    <div className={styles.navigation}>
                      <a
                        style={{ fontSize: "15px" }}
                        className="nav-link d-flex hover-underline-animation mx-1"
                        href="/our-projects"
                      >
                        {t("Our_projects")}
                      </a>
                    </div>
                  </div>

                  {/* <div className="">
                    <div className={styles.navigation}>
                      <a
                        style={{ fontSize: "15px" }}
                        className="nav-link d-flex hover-underline-animation mx-1"
                        href="/our-projects"
                      >
                        {t("Our_projects")}
                      </a>
                    </div>
                  </div> */}
                  <div>
                    <div className={styles.navigation}>
                      <div className="d-flex align-items-center">
                        <span>
                          {t("Our_services")}
                          <KeyboardArrowDownIcon
                            style={{ fontSize: "11px " }}
                          />
                        </span>
                      </div>

                      <div
                        className={styles.navigation_content_last}
                        style={{ minWidth: "10vw !important" }}
                      >
                        <div>
                          <HashLink to="/studio#contactUsStudio">
                            {t("Join_EYouth_Instructors")}
                          </HashLink>
                        </div>
                        {/* <div>
                          <a href="https://business.eyouthlearning.com/">
                            {t("Train_your_business_team")}
                          </a>
                        </div> */}

                        {/* <li> <a href="/wfp?page=1" >WF - Program</a></li> */}
                      </div>

                    </div>
                  </div>
                  {/* <div className="">
                    <div className={styles.navigation}>
                      <a
                        style={{ fontSize: "15px" }}
                        className="nav-link d-flex hover-underline-animation mx-1"
                        href="/about-eyouth"
                      >
                        {t("About")}
                      </a>
                    </div>
                  </div> */}
                </ul>
              </div>
            )}
          </div>
          {pathname !== "/checkout-preview" && (
            <div
              className="col-3 d-flex justify-content-around align-items-center"
              title="language"
            >
              <Searchbar classes=" p-0 m-0 w-100" />
              {/* <LanguageIcon className='language-button me-3' onClick={handleClicklanguage} /> */}
            </div>
          )}

          {pathname !== "/checkout-preview" && (
            <div className="col-3 d-flex justify-content-center align-items-center my-0 ">
              <LangButton />
              {Object.keys(session).length === 0 &&
                pathname !== "/signin" &&
                pathname !== "/signup" &&
                pathname !== "/studio" && (
                  <>
                    <button
                      aria-label="button to be clicked"
                      aria-labelledby="button to be clicked"
                      className={`btn btn-primary mx-1  ${styles.btn_signin}`}
                      onClick={navigatesignin}
                    // style={{ width: "40% !important" }}
                    >
                      {t("Sign In")}
                    </button>
                    <PopUpJoinUs
                      title={t("Join_Us")}
                      styles={`btn  mx-1 ${styles.btn_signup}`}
                    />
                  </>
                )}
              {Object.keys(session).length > 0 && pathname !== "/studio" && (
                <div className="d-flex w-75 justify-content-around align-items-center">
                  <Button
                    aria-label="button to be clicked"
                    aria-labelledby="button to be clicked"
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className={`${styles.profile_btn} bg-light border-button rounded p-1`}
                  >
                    <AccountCircleIcon className={styles.icon_color} />

                    <p className="p-0 m-0">{usernameInMobileView}</p>
                    <KeyboardArrowDownIcon />
                  </Button>
                  <Menu
                    style={{ zIndex: "999999" }}
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      style={{ fontFamily: "Tajawal" }}
                      onClick={handleClose}
                      className="fs-5"
                    >
                      <Link to="/Mycourses"> {t("My Courses")} </Link>
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: "Tajawal" }}
                      onClick={handleClose}
                      className="fs-5"                    >
                      <a
                        href={`https://apps.Taibah.eyouthlearning.com/profile/u/${session?.username}`}

                      >
                        {t("My_profile")}
                      </a>
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: "Tajawal" }}
                      onClick={handleClose}
                      className="fs-5"
                    >
                      <a href="https://apps.Taibah.eyouthlearning.com/account">
                        {t("Account_Settings")}
                      </a>
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: "Tajawal" }}
                      onClick={handleClose}
                      className="fs-5"
                    >
                      <a href="https://apps.Taibah.eyouthlearning.com/orders/orders">
                        {t("My_orders")}
                      </a>
                    </MenuItem>
                    <MenuItem
                      style={{ fontFamily: "Tajawal" }}
                      onClick={handleLogOut}
                    >
                      <button
                        aria-label="button to be clicked"
                        aria-labelledby="button to be clicked"
                        className="btn btn-light border m-auto"
                      >
                        {t("Sign Out")}
                      </button>
                    </MenuItem>
                  </Menu>
                  <a
                    href="https://apps.Taibah.eyouthlearning.com/payment"
                    className="mx-1"
                  >
                    <strong>
                      <ShoppingCartIcon className={styles.shoppingCart} />
                    </strong>
                  </a>
                </div>
              )}
              {pathname === "/studio" && (
                <button
                  aria-label="button to be clicked"
                  aria-labelledby="button to be clicked"
                  className={`btn   mx-1 ${styles.btn_signin} d-flex justify-content-around align-items-center py2`}
                  onClick={() => {
                    if (Object.keys(session).length > 0)
                      window.location.href = `${STUDIO_BASE_URL}/home/`;
                    else navigate(`/signin?redirect=${STUDIO_BASE_URL}/home/`);
                  }}
                >
                  {i18n.language === "ar" ? (
                    <LoginIcon className={`mx-1  fs-3  `} />
                  ) : (
                    <LogoutIcon className={`mx-1  fs-3  `} />
                  )}
                  <span>{t("Studio")}</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
