import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from "react";
import TagManager from 'react-gtm-module';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import './App.css';
import { LTRTheme, RTLTheme, cacheLTR, cacheRTL } from "./assets/styles/theme";
import "./i18n";
// import LoadingImg from './components/Loading-Img/LoadingImg';
import Navbar from './components/navbar/navbar';
import { APIExperience, STUDIO_BASE_URL } from "./config/constants";
import { login, publicLogin } from "./config/state/actions";
import { fetchData } from './config/store/reducers/userLocation/userLocationActions';
import About from './pages/About/About';
import CourseEnrollment from './pages/BusinessDashboard/CourseEnrollment/CourseEnrollment';
import EmailNotification from './pages/BusinessDashboard/EmailNotification/EmailNotification';
import GroupDetails from './pages/BusinessDashboard/GroupDetails/GroupDetails';
import Groups from './pages/BusinessDashboard/Groups/Groups';
import LayoutDashboard from './pages/BusinessDashboard/LayoutDashboard/LayoutDashboard';
import LmsTrack from './pages/BusinessDashboard/LmsTrack/LmsTrack';
import Registeration from './pages/BusinessDashboard/Register/Registeration';
import UserProfile from './pages/BusinessDashboard/UserProfile/UserProfile';
import UsersTracking from './pages/BusinessDashboard/UsersTracking/UsersTracking';
import RandomCode from './pages/FormComponent/Form';
import Partners from "./pages/Partners/Partners";
import Services from './servises/services';
// import axios from 'axios';

// import SDAProgram from './pages/SDAProgram/SDAProgram';

const Footer = React.lazy(() => import('./components/footer/footer'));
const CourseDetails = React.lazy(() => import('./pages/courseDetails/CourseDetails'));
const Home = React.lazy(() => import('./pages/home/home'));
const CourseReviews = React.lazy(() => import('./pages/coursereviwes/coursereviwes'));
const Studio = React.lazy(() => import('./pages/Studio/Studio'));
const Signup = React.lazy(() => import('./pages/signup/signup'));
const Signin = React.lazy(() => import('./pages/signin/signin'));
const Thankyou = React.lazy(() => import('./pages/thankyou/thankyou'));
const Mycourses = React.lazy(() => import('./pages/myCourses/mycourses'));
const Instructor = React.lazy(() => import("./pages/instructor/Instructor"));
const Instructors = React.lazy(() => import('./pages/instructors/Instructors'));
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const TermsAndCond = React.lazy(() => import('./pages/TermsAndCond/TermsAndCond'));
const PolicyAndPrivecy = React.lazy(() => import('./pages/PolicyAndPrivecy/PolicyAndPrivecy'));
const AddReviews = React.lazy(() => import('./pages/AddReview/AddReview'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const AllCoursesAndFilteration = React.lazy(() => import('./components/AllCoursesAndFilteration/AllCoursesAndFilteration'));
const Loading = React.lazy(() => import('./pages/loading/loading'));
const NotFoundPage = React.lazy(() => import('./pages/Not-Found/Not-Found'));
const CheckoutPreview = React.lazy(() => import("./pages/CheckoutPreview/CheckoutPreview"));
const ProgramsLandingPage = React.lazy(() => import("./pages/ProgramsLandingPage/ProgramsLandingPage"));
const SingleProgram = React.lazy(() => import("./pages/SingleProgram/SingleProgram"));
const ContactUs = React.lazy(() => import("./components/contactUs/contactUs"));
const DynaminPage = React.lazy(() => import("./pages/DynaminPage/DynaminPage"));
const EYouthProjectsPage = React.lazy(() => import("./pages/EYouthProjectsPage/EYouthProjectsPage"));
const ClimateChangeProgram = React.lazy(() => import("./pages/ClimateChangeProgram/ClimateChangeProgram"));
const SustainabilityCorporateProgram = React.lazy(() => import("./pages/SustainabilityCorporateProgram/SustainabilityCorporateProgram"));
const UserInfoForm = React.lazy(() => import("./components/UserInfoForm/UserInfoForm"));
const FAQ = React.lazy(() => import("./pages/FAQ's/FAQ's"));
const PlatformPolicy = React.lazy(() => import("./pages/PlatformPolicy/PlatformPolicy"));



// const SDAProgram = React.lazy(() => import("./pages/SDAProgram/SDAProgram"));

const AdminEnrollment = React.lazy(() => import("./pages/AdminEnrollment/AdminEnrollment"));



function App() {
  // const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
  const location = useLocation();

  // const navigate = useNavigate()
  const [URLSearchParams] = useSearchParams();
  const [token, setToken] = useState();
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    dispatch(fetchData());
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("client_id", "HztEnkrrBzwaw6qaSydySyorJ8WUA3FyYLjGWcB2");
    formData.append("client_secret", "733cFuB37jlSHr63jCAAaFzOv2wp0YQjRT8kzMkHRKQPDCncRruMOVBqnXWprB6ELX00XEVyMJoFBbYWxyml9pjNNXdyzOcxbwn0CrZWq2uu5lSq8EkMlKqUzwDxSD4L");
    formData.append("token_type", 'jwt');
    dispatch(publicLogin(formData));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-PG4JD4HM'
    };
    TagManager.initialize(tagManagerArgs);
  }, [location]);

  useEffect(() => {
    if (token?.length > 10)
      localStorage.setItem("user_stu", token);
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase().includes("Studio")) {
      window.location.href = `${APIExperience}/login?next=/oauth2/authorize%3Fclient_id%3Dcms-sso%26redirect_uri%3Dhttps%253A%252F%252F${STUDIO_BASE_URL.slice(8, STUDIO_BASE_URL.length)}%252Fcomplete%252Fedx-oauth2%252F%253Fredirect_state%253Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26state%3Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26response_type%3Dcode%26scope%3Duser_id%2Bprofile%2Bemail`;
    }
    setToken(Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString("utf-8"));
    // eslint-disable-next-line
  }, [location, URLSearchParams]);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
    // console.log(session , "se")
    if (session.expiration_time && session.expiration_time < new Date().getTime()) {
      const formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", "login-service-client-id");
      formData.append("refresh_token", session.refresh_token);
      dispatch(login(formData));
    }
    if (Object.keys(session).length > 0) {
      Services.getUserLanguageProficiencies(session?.username, session.access_token).then((res) => {
        // let lang = res.data["pref-lang"];
        // i18n.changeLanguage(lang);
        // console.log("redata" , res.language_proficiencies[0]["code"]);
        
        // hashing language cuz it returns undefined
        // let languageproficiencies = res?.language_proficiencies[0]["code"] || "ar"
        // i18n.changeLanguage(languageproficiencies);
        document.documentElement.lang = `${i18n.language === "en" ? "en" : "ar"}`;
        document.dir = i18n.language === "en" ? "ltr" : "rtl";
        // document.documentElement.lang = "ar";
        // document.dir = i18n.language === "rtl";
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLanguage(i18n.language);
    document.documentElement.lang = `${i18n.language === "en" ? "en" : "ar"}`;
    document.dir = i18n.language === "en" ? "ltr" : "rtl";
    // setLanguage("ar");
    // document.documentElement.lang = "ar";
    // document.dir = i18n.language === "rtl";
  }, [i18n.language]);

  return (
    <CacheProvider value={language === "ar" ? cacheRTL : cacheLTR}>
      <ThemeProvider theme={language === "ar" ? RTLTheme : LTRTheme}>
        <div className="m-0 p-0">
          {location.pathname !== '/loading' && <Navbar token={token} />}
          {/* {(location.pathname === '/signin' || location.pathname === '/signup') ? <></> : <DownloadButtons />} */}
          <ScrollToTop />
          <div style={{ minHeight: "50vh" }}>
            <Routes>
              <Route exact path="/" element={<Home />}> </Route>
              <Route path="/signup" element={<Signup />}></Route>
              <Route path="/signin" element={<Signin />}></Route>
              <Route path="/all-courses" element={<AllCoursesAndFilteration />}> </Route>
              <Route path="/thankyou" element={<Thankyou />}> </Route>
              <Route path="/Mycourses" element={<Mycourses />}> </Route>
              <Route path="/instructors" element={<Instructors />}> </Route>
              <Route path="/course-reviews/:id" element={<CourseReviews />}> </Route>
              <Route path="/instructor/:id" element={<Instructor />}> </Route>
              <Route path="/add-review/:id" element={<AddReviews />}> </Route>
              <Route path="/studio" element={<Studio />}> </Route>
              <Route path="/details/:id" element={<CourseDetails />}> </Route>
              <Route path="/terms-conditions" element={<TermsAndCond />}></Route>
              <Route path="/Policy" element={<PolicyAndPrivecy />}></Route>
              <Route path="/logout" element={<Logout />}></Route>
              <Route path="/loading" element={<Loading />}></Route>
              <Route path="/partners" element={<Partners />}></Route>
              <Route path="/checkout-preview/" element={<CheckoutPreview />}></Route>
              <Route path="/programs/" element={<ProgramsLandingPage />}></Route>
              <Route path="/program/:id" element={<SingleProgram />}></Route>
              <Route path="/contact-us" element={<ContactUs pageName="contact_us" />}></Route>
              <Route path="/quick-learnings" element={<DynaminPage />}></Route>
              <Route path="/live-courses" element={<DynaminPage />}></Route>
              <Route path="/wfp" element={<DynaminPage />}></Route>
              <Route path="/eyouthblackfriday" element={<DynaminPage />}></Route>
              {/* <Route path="/nbd" element={<DynaminPage />}></Route> */}
              <Route path="/our-projects" element={<EYouthProjectsPage />}></Route>
              <Route path="/scip" element={<SustainabilityCorporateProgram />}></Route>
              <Route path="/climate-program" element={<ClimateChangeProgram />}></Route>
              <Route path="/sdap" element={<ClimateChangeProgram />}></Route>
              <Route path="/enrollment" element={<AdminEnrollment />}></Route>
              <Route path="/user-form" element={<UserInfoForm />}></Route>
              <Route path="/faq" element={<FAQ />}></Route>
              <Route path="/about-eyouth" element={<About />}></Route>

              <Route path="/platform-policy" element={<PlatformPolicy />}></Route>
              <Route path="/admin-dashboard" element={<LayoutDashboard />}>
                <Route path="register" element={<Registeration />}></Route>
                <Route path="course-enrollment" element={<CourseEnrollment />}></Route>
                <Route path="notification" element={<EmailNotification />}></Route>
                <Route path="lms-track" element={<LmsTrack />}></Route>
                <Route path="users" element={<UsersTracking />}></Route>
                <Route path="groups" element={<Groups />}></Route>
                <Route path="group-details/:groupId" element={<GroupDetails />}></Route>

              </Route>
              <Route path="/userProfile/:id" element={<UserProfile />}></Route>
              <Route path="/users-form" element={<RandomCode />}></Route>





              <Route path="*" element={<NotFoundPage />} /></Routes>
          </div>
          {location.pathname !== '/loading' && <Footer />}
        </div>
      </ThemeProvider>
    </CacheProvider>

  );
}

export default App;